import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { GitlabService } from '../gitlab/gitlab.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  loading = true;
  groups$ = this.gs.getGroups().pipe(
    mergeMap(groups => {
      if (groups.length > 0) {
        return of(groups);
      }
      return this.route.navigateByUrl('/projects');
    }),
    tap(() => this.loading = false)
  );
  constructor(private gs: GitlabService, private route: Router, private cd: ChangeDetectorRef) { }

  // fixes ExpressionChangedAfterItHasBeenCheckedErrors in tests
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
