import { Component } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, mergeMap, pluck } from 'rxjs/operators';
import { Group, Job, Project } from './gitlab/gitlab';
import { GitlabService } from './gitlab/gitlab.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof ActivationEnd),
    pluck<ActivationEnd, ActivatedRouteSnapshot>('snapshot'),
    mergeMap(({ url: snapshotUrl, params: { groupId, projectId, jobId } }) => {
      const obs: Observable<any>[] = [];

      const makeUrl = (elm: Group | Project | Job) => {
        const idIndex = snapshotUrl.findIndex(u => +u.path === elm.id) + 1;
        const url = snapshotUrl.slice(0, idIndex).join('/');
        return { ...elm, url };
      };

      if (groupId) {
        obs.push(this.gs.getGroup(+groupId).pipe(map(makeUrl)));
      }
      if (projectId && jobId) {
        obs.push(this.gs.getProject(+projectId));
        obs.push(this.gs.getJobDetails(+projectId, +jobId).pipe(map(makeUrl)));
      }
      if (obs.length === 0) {
        obs.push(of([]));
      }
      return forkJoin(obs);
    })
  );
  constructor(private router: Router, private gs: GitlabService) { }
}
