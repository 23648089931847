import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { GitlabService } from '../gitlab/gitlab.service';

@Component({
  selector: 'app-log-display',
  templateUrl: './log-display.component.html',
  styleUrls: ['./log-display.component.scss']
})
export class LogDisplayComponent implements AfterViewInit {
  loading = true;
  job$ = this.route.params.pipe(
    mergeMap(params => forkJoin({
      details: this.gs.getJobDetails(+params.projectId, +params.jobId),
      traceFile: this.gs.getTraceFile(+params.projectId, +params.jobId)
    })),
    tap(() => this.loading = false)
  );
  constructor(private route: ActivatedRoute, private gs: GitlabService, private cd: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
